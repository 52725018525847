import React, { useEffect } from "react";
import { useState } from "react";
import axios from "../../../axios";
import Account from "./Account";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
function AccountList({ setCurrentAccount, currentAccount, reload }) {
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    const getAccounts = async () => {
      const { data } = await axios.get("/accounts");
      if (data) {
        console.log('account data', data)
        setAccounts([...data]);
      }
    };
    
      getAccounts();
    
  }, [reload]);
  console.log('accounts', accounts)
  return (
    <ListGroup>
      {accounts.map((account) => {
        return (
          <ListGroup.Item
            key={account.login_account_id}
            className={
              currentAccount?.login_account_id === account?.login_account_id
                ? "active-account"
                : "account-list-item"
            }
            onClick={() => setCurrentAccount(account)}
          >
            {account?.login_phone}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export default AccountList;
