import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import axios from "../../../axios";
function UploadXLSX({ reloadTable }) {
  let fileInputRef = useRef()
  const [file, setFile] = useState();
  const [grouping, setGrouping] = useState(5);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  console.log("grouping", grouping);
  const handleSubmit = (e) => {
    console.log("Uploading");

    setUploading(true);
    setError("");
    e.preventDefault();
    let form = e.currentTarget;
    if (!file.name.includes("xlsx")) {
      setError(
        "Incorrect file type. Please select a file ending with '.xlsx' from Excel."
      );
      setUploading(false)
      return false;
    }

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setUploading(false)
      setValidated(true);
    } else {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("grouping", grouping);
      axios
        .post("/upload/xlsx", formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Upload successful.",
            text: "Records will appear below. Reload if this does not happen soon. ",
            timer: 6000,
          });
          setUploading(false);
          fileInputRef.current.value = ""
          setFile();
          reloadTable();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Error Uploading File",
            text: "Please make sure it is in .xlsx format. Notify development if this continues.",
            timer: 10000,
          });

          setUploading(false);
        });
    }
  };
  console.log("file", file);
  return (
    <Form
      className="p-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        <Form.Label>
          Upload a<b> .xlsx</b> file from Excel to queue more invoices.
        </Form.Label>
        <Form.Control
          required
          type="file"
          // name=""
          ref={fileInputRef}
          onChange={(e) => setFile(e.target.files[0])}
        />
        <span className="text-danger">{error}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email Attachment Count</Form.Label>
        <em className="d-block text-secondary">
          This is the max number of attachments to include in each email for
          this batch.{" "}
        </em>
        <div>
          <Form.Check
            // disabled
            inline
            type="radio"
            label="1"
            id="pdf-1"
            checked={grouping === 1}
            onChange={() => setGrouping(1)}
          />
          <Form.Check
            // disabled
            inline
            type="radio"
            label="2"
            id="pdf-2"
            checked={grouping === 2}
            onChange={() => setGrouping(2)}
          />
          <Form.Check
            // disabled
            inline
            type="radio"
            label="3"
            id="pdf-3"
            checked={grouping === 3}
            onChange={() => setGrouping(3)}
          />
          <Form.Check
            // disabled
            inline
            type="radio"
            label="4"
            id="pdf-4"
            checked={grouping === 4}
            onChange={() => setGrouping(4)}
          />
          <Form.Check
            // disabled
            inline
            type="radio"
            label="5"
            id="pdf-5"
            checked={grouping === 5}
            onChange={() => setGrouping(5)}
          />
        </div>
      </Form.Group>

      <div className="d-flex justify-content-end">
        <Button
          disabled={!file?.name || uploading}
          type="submit"
          className="my-4 w-50"
        >
          {uploading ? (
            <Spinner variant="light" as="span" size="sm" animation="grow"/>
          ) : (
            "Upload"
          )}
        </Button>
      </div>
    </Form>
  );
}

export default UploadXLSX;
