import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
function EmailInstructions() {
  return (
    <Container>
      <Row>
        <Col>
          <hr />
          <h2>
            If you want me to do this for you, I am happy to do so! Call or text
            385.200.4172 to set up a time with me today.
          </h2>
          <hr />
          <h2 className="text-center">Set up your new email address.</h2>
          <hr />
          <h2>Removing your old account. (Not always necessary).</h2>
          <h4>1. Open Outlook and click File in the top left corner.</h4>
          <h4>2. Click Account Settings</h4>
          3. Click on Manage Profiles
          <p>
            Another box called <em>Mail Setup - Outlook </em> will pop up.
          </p>
          <img
            src="/images/email/accountsettings.png"
            className="inst-img my-3"
            alt=""
          />
          <h4>4. Click on Email Accounts...</h4>
          <img
            src="/images/email/emailaccounts.png"
            className="inst-img my-3"
            alt=""
          />
          <h4>5. Remove your old account</h4>
          <p>Your emails are all still backed up on the server.</p>
          <img
            src="/images/email/removeoldaccount.png"
            className="inst-img my-3"
            alt=""
          />
          <p>Continue with the deletion.</p>
          <img
            src="/images/email/yesdelete.png"
            className="inst-img my-3"
            alt=""
          />
          <hr />
          <h2>Check your Inbox</h2>
          <h4>1. Open Outlook</h4>
          <h4>2. Look at your inbox.</h4>
          <ul>
            <li>
              It may show empty at first but it should quickly start updating
              with all of your emails. If you have a lot of emails, this could
              take a while.
            </li>
            <li>
              {" "}
              If you are still downloading emails, any emails you try to send
              might sit in the Outbox until you are done downloading.
            </li>
          </ul>
          <img
            src="/images/email/folderspopulated.png"
            className="inst-img my-3"
            alt=""
          />
          <div className="p-3 border rounded-3  shadow bg-secondary text-light">
            <h4>OPTIONAL: Make sure you are subscribed to all folders.</h4>
            <h5>Right-Click your Inbox then Click IMAP Folders</h5>
            <img
              src="/images/email/imapfolders.png"
              className="inst-img my-3"
              alt=""
            />
            <ol>
              <li className="text-light">
                <h5>Click on Query.</h5>
              </li>
              <li className="text-light">
                <h5>Click on All.</h5>
              </li>
              <li className="text-light">
                <h5>
                  Click the first folder, scroll to the bottom of the list and
                  hold shift when you click the last folder. They should all be
                  blue..
                </h5>
              </li>
              <li className="text-light">
                <h5>Click Subscribe</h5>
              </li>
              <li className="text-light">
                <h5>Click Apply</h5>
              </li>
              <li className="text-light">
                <h5>Click OK.</h5>
              </li>
            </ol>
            <img
              src="/images/email/subscribe.png"
              className="inst-img my-3"
              alt=""
            />
          </div>
          <h4>13. Test the Send/Receive button.</h4>
          <p>
            It is a good idea to send test emails to your personal email account
            and back. Feel free to use{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://business143.web-hosting.com:2096"
            >
              webmail
            </a>{" "}
            if you are unsure if your emails are done downloading.
          </p>
          <h4>
            14. All done! Contact me with any questions, concerns or problems
            you have.{" "}
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

export default EmailInstructions;
