import React, {useEffect} from 'react';

function EmailLogin() {
    useEffect(() => {
        window.location.href = "https://webmail.retailconsultingteam.com";
      }, []);
  return <h3>Redirecting...</h3>;
}

export default EmailLogin;
