import axios from "axios";
const { REACT_APP_PRODUCTION } = process.env;
let baseURL = "https://api.retailconsultingteam.com";
if (REACT_APP_PRODUCTION === "false") {
  baseURL = "http://localhost:8462";
}
console.log('react production', REACT_APP_PRODUCTION)
const instance = axios.create({
  baseURL,
  withCredentials: true,
});
export default instance;
