import React, { useEffect, useState } from "react";
import { useUser } from "../../UserContext";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AccountManager from "./Accounts/AccountManager";
import { ListGroup } from "react-bootstrap";
import Invoicing from "./Invoicing/Invoicing";
import BlogManager from "./Blog/BlogManager";
function Admin() {
  let [selectedTool, setSelectedTool] = useState("blog");
  let user = useUser("user");
  const tools = {
    accounts: <AccountManager />,
    invoicing: <Invoicing />,
    blog: <BlogManager />,
  };

  console.log("user", user);
  return user?.currentUser?.permissions?.user ? (
    <Row>
      <Col xs="12" md="1" className="text-left  ps-0 border-right">
        <ListGroup.Item
          className={
            selectedTool === "blog" ? "active-account" : "account-list-item"
          }
          onClick={() => setSelectedTool("blog")}
        >
          <i class="bi bi-file-earmark-richtext"></i>
        </ListGroup.Item>
        <ListGroup className="">
          <ListGroup.Item
            className={
              selectedTool === "invoicing"
                ? "active-account"
                : "account-list-item"
            }
            onClick={() => setSelectedTool("invoicing")}
          >
            <i class="bi bi-receipt"></i>
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedTool === "accounts"
                ? "active-account"
                : "account-list-item"
            }
            onClick={() => setSelectedTool("accounts")}
          >
            <i class="bi bi-chat-dots"></i>
          </ListGroup.Item>
        </ListGroup>
      </Col>

      <Col xs="12" md="11" className="bg-light-grey p-2">
        {tools[selectedTool]}
      </Col>
    </Row>
  ) : (
    <div></div>
  );
}

export default Admin;
