import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../../axios";
import Button from "react-bootstrap/Button";
import "./AccountManager.css";
import Swal from "sweetalert2";
import PhoneMessages from "./PhoneMessages";
function Account({ currentAccount, clients, reloadAll }) {
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState({
    client_id: 0,
    email_alias: "",
  });
  useEffect(() => {
    setEditMode(false);

    setState({
      ...state,
      client_id: currentAccount.client_id || "",
      email_alias: currentAccount.email_alias,
    });
  }, [currentAccount.login_account_id]);
  const handleSubmit = async () => {
    await axios
      .put("/account", {
        login_account_id: currentAccount.login_account_id,
        ...state,
      })
      .catch((err) => console.log("err", err));
    reloadAll();
    Swal.fire({
      icon: "success",
      title: "Account Information Updated",
      timer: 1000,
      showConfirmButton: false,
    });
  };
  const handleDelete = async () => {
    Swal.fire({
      icon: "warning",
      title: "Delete this number and email?",
      text: "This will delete the phone number, email, and messages associated with this account. The client will remain below.",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete("/account/" + currentAccount.login_account_id);
        reloadAll();
        Swal.fire({
          icon: "success",
          title: "Account Information deleted.",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    });
  };
  return (
    currentAccount.login_account_id > 0 && (
      <Container>
        <h2>Account Information</h2>
        <div>
          <u
            onClick={() => setEditMode(!editMode)}
            className="text-primary float-end  pointer"
          >
            {editMode ? "Cancel" : "Edit"}
          </u>
        </div>
        {editMode ? (
          <div>
            Client
            <Form.Select
              value={state.client_id}
              onChange={(e) => {
                setState({ ...state, client_id: e.target.value });
              }}
            >
              {clients.map((client) => {
                return (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client_name}
                  </option>
                );
              })}
              <option value="">No associated client.</option>
            </Form.Select>
            Email
            <Form.Control
              type="text"
              onChange={(e) =>
                setState({ ...state, email_alias: e.target.value })
              }
              value={state.email_alias}
            />
            <Button className="my-2 " onClick={() => handleSubmit()}>
              Submit Changes
            </Button>
            <u
              onClick={() => handleDelete()}
              className="pointer text-secondary float-end my-3"
            >
              Delete
            </u>
          </div>
        ) : (
          <div>
            <div>Phone: {currentAccount.login_phone}</div>
            <div>
              Associated Client:{" "}
              {currentAccount.client_name || "No associated client."}
            </div>
            <div>
              Associated Email:{" "}
              {currentAccount.email_alias || "No associated email."}
            </div>
          </div>
        )}
        <PhoneMessages login_account_id={currentAccount.login_account_id} />
      </Container>
    )
  );
}

export default Account;
