import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "../../../axios";
import Swal from "sweetalert2";
function AddPhoneModal({ setModal, show, reloadAll }) {
  const [state, setState] = useState({
    email_alias: "",
    login_phone: "",
  });
  console.log("state", state);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post("/account", {
      ...state,
      login_phone: state.login_phone.replaceAll("+1", ""),
    });
    setModal(false);
    reloadAll();
    Swal.fire({
      icon: "success",
      title: "Account added!",
      timer: 1000,
      showConfirmButton: false,
    });
  };
  return (
    <Modal onHide={() => setModal(false)} size="lg" show={show} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Phone / Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>10 digit (No country code +1 ) Account Phone Number</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                setState({ ...state, login_phone: e.target.value })
              }
              value={state.login_phone}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Account Email (Optional) You can edit the email later.
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                setState({ ...state, email_alias: e.target.value })
              }
              value={state.email_alias}
            />
          </Form.Group>
          <div>
            <Button className="float-end mt-3" type="submit">
              Add Account Information
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setModal(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPhoneModal;
