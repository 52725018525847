import axios from "../../../axios";
import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";
import dateFormat from "dateformat";

function PhoneMessages({ login_account_id }) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getMessages = async () => {
      setLoading(true);
      const { data } = await axios.get(
        "/account/" + login_account_id + "/messages"
      );
      if (data) {
        setMessages(data);
      }
      setLoading(false);
    };
    getMessages();
  }, [login_account_id]);
  return (
    <div className="messages-container">
      <h3>Text Messages: </h3>
      {!loading ? (
        messages.length > 0 ? (
          messages.map((message) => {
            return (
              <Row key={message.phone_message_id} className="message-row my-1">
                <Col xs="4" lg="3" className="text-end message-row-date">
                  {dateFormat(message.date_received, "mm/dd/yy, h:MM TT Z")}
                </Col>
                <Col>"{message.message_body}"</Col>
              </Row>
            );
          })
        ) : (
          <h6 className="text-primary">No messages received yet.</h6>
        )
      ) : (
        <Container className="main-content d-flex justify-content-center">
          <Spinner animation="grow" />
        </Container>
      )}
    </div>
  );
}

export default PhoneMessages;
