import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Contact from "./Contact";
function ContactPage() {
  return (
    <div>
      <Row className="d-flex flex-column align-items-center my-5">
        <Col xs="12" md="8" lg="6">
          <h1 className="text-blue text-center">CONTACT US</h1>
          <p>
            Our team is waiting to help your company recover the money Walmart
            owes you. Our process is fast, and easy, requiring little to no work
            from your team. Once you call us, email us, visit us, or fill out
            the form below, you’ll have done the hardest part. All that will be
            left for you to do is wait for us to get you your money back.
          </p>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center my-5">
        <Col xs="12" md="8" lg="8">
          <Row>
            <Col
              xs="12"
              lg="4"
              className="d-flex flex-column align-items-center"
            >
              <img
                src="/images/mail.png"
                className="contact-img"
                alt="Envelope icon."
              />
              <p className="contact-text my-3">
                contact@retailconsultingteam.com
              </p>
            </Col>
            <Col
              xs="12"
              lg="4"
              className="d-flex flex-column align-items-center"
            >
              <img
                src="/images/phone.png"
                className="contact-img"
                alt="Envelope icon."
              />
              <p className="contact-text my-3">(479) 365-0109</p>
            </Col>
            <Col
              xs="12"
              lg="4"
              className="d-flex flex-column align-items-center"
            >
              <img
                src="/images/building.png"
                className="contact-img"
                alt="Envelope icon."
              />
              <p className="contact-text my-3">
                900B South Walton Blvd Suite 1, Bentonville, AR 72712s.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col
          xs="12"
          md="6"
          lg="4"
          className="d-flex justify-content-center my-2 "
        >
          <img
            className="w-75 rounded-3"
            src="/images/building/1.jpg"
            alt="Raintree Business Park sign"
            loading="lazy"
          />
        </Col>
        <Col
          xs="12"
          md="6"
          lg="4"
          className="d-flex justify-content-center my-2 "
        >
          <img
            className="w-75 rounded-3"
            src="/images/building/2.png"
            alt="Raintree Business Park sign"
            loading="lazy"
          />
        </Col>
        <Col
          xs="12"
          md="6"
          lg="4"
          className="d-flex justify-content-center my-2 "
        >
          <img
            className="w-75 rounded-3 location-img"
            src="/images/building/3.png"
            alt="Raintree Business Park sign"
            loading="lazy"
          />
        </Col>
      </Row>
      <Row>
        <h3 className="text-center my-5">
          Come visit us at the Raintree Business Park, Building 900B, Suite #1.
          We would love to see you!
        </h3>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="8">
          <Contact />
        </Col>
      </Row>
    </div>
  );
}

export default ContactPage;
