import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../../axios";
import Swal from "sweetalert2";
import InvoiceTable from "./InvoiceTable";
function InvoiceTables({ reloadTrigger, setReloadTrigger, reloadTable }) {
  const [loaded, setLoaded] = useState(false);
  const [unsentInvoices, setUnsentInvoices] = useState([]);
  const [sentInvoices, setSentInvoices] = useState([]);
  const [queuedInvoices, setQueuedInvoices] = useState([]);
  const loadData = () => {
    if (loaded) {
      setLoaded(false);
    }
    // setSelectAll(false);
    // setSelected([]);
    axios
      .get("/invoices")
      .then((res) => {
        if (res.data) {
          let unsent = res.data.filter(
            (inv) => !inv.email_sent && inv.do_not_email
          );
          let sent = res.data.filter(
            (inv) => inv.email_sent || inv.email_error
          );
          let queued = res.data.filter(
            (inv) => !inv.email_sent && !inv.do_not_email
          );
          setUnsentInvoices(unsent || []);
          setSentInvoices(sent || []);
          setQueuedInvoices(queued || []);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error getting invoices.",
          text: "Please try again.",
          timer: 4000,
        });
      });
  };

  useEffect(() => {
    loadData();
  }, [reloadTrigger]);

  return (
    <Container>
      <hr />
      <InvoiceTable
        type={"queue"}
        invoices={queuedInvoices}
        reloadTable={reloadTable}
        reloadTrigger={reloadTrigger}
        setReloadTrigger={setReloadTrigger}
        loaded={loaded}
      />

      <InvoiceTable
        type={"unsent"}
        invoices={unsentInvoices}
        reloadTable={reloadTable}
        reloadTrigger={reloadTrigger}
        setReloadTrigger={setReloadTrigger}
        loaded={loaded}
      />

      <InvoiceTable
        type={"sent"}
        invoices={sentInvoices}
        reloadTable={reloadTable}
        reloadTrigger={reloadTrigger}
        setReloadTrigger={setReloadTrigger}
        loaded={loaded}
      />
    </Container>
  );
}

export default InvoiceTables;
