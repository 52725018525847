import React, { useEffect, useState } from "react";
import "./Header.css";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import NavDropdown from "react-bootstrap/NavDropdown";
import axios from "../../axios";
function Header() {
  let location = useLocation();
  console.log("location", location);

  const [articles, setArticles] = useState([]);
  const getArticles = () => {
    axios
      .get("/header-blog-data")
      .then((res) => {
        setArticles(res.data);
      })
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    getArticles();
  }, []);
  const [showArticles, setShowArticles] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg" variant="light">
      <Container className="d-flex justify-content-between">
        <Link className="header-links text-secondary" to="/">
          <Navbar.Brand>
            <img
              height="40"
              src="/images/Logo.png"
              alt=""
              className="header-logo"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <div className="nav-link">
              <Link
                className={` header-links${
                  location.pathname === "/" ? "-active" : ""
                }`}
                to="/"
              >
                Walmart Deduction Recovery
              </Link>
            </div>
            <div className="nav-link">
              <Link
                className={` header-links${
                  location.pathname === "/general-deduction-recovery"
                    ? "-active"
                    : ""
                }`}
                to="/general-deduction-recovery"
              >
                General Deduction Recovery
              </Link>
            </div>
            <div className="nav-link">
              <Link
                className={` header-links${
                  location.pathname === "/team" ? "-active" : ""
                }`}
                to="/team"
              >
                Meet the Team
              </Link>
            </div>
            {/* <div className="nav-link "> */}
            {/* <NavDropdown className="header-links " title="Articles">
              {articles.map((article) => (
                <NavDropdown.Item key={article.slug}>
                  <Link
                    className="Navdropdown-item"
                    to={`/article/${article.slug}`}
                  >
                    {article.title}
                  </Link>
                </NavDropdown.Item>
              ))}
            </NavDropdown> */}
            {/* </div> */}
            <div className="nav-link">
              <Link
                className={` header-links${
                  location.pathname === "/contact" ? "-active" : ""
                }`}
                to="/contact"
              >
                Contact
              </Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
