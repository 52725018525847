import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import BlogEditor from "./BlogEditor";
import BlogPostTable from "./BlogPostTable";
function BlogManager() {
  const [showBlogForm, setShowBlogForm] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const reloadTableData = () => {
    setReloadTable(!reloadTable);
  }
  return (
    <Container>
      <h2>Blog Manager</h2>
      <Row className='mb-3'>
        <Col>
          <Button
          variant={showBlogForm ? "secondary" : "primary"}
          onClick={() => setShowBlogForm(!showBlogForm)}
          >{!showBlogForm ? "Create Blog Post" : "Hide Blog Post Editor"}</Button>
        </Col>
      </Row>
      {showBlogForm && (
        <Row>
          <Col>
            <BlogEditor setShowBlogForm={setShowBlogForm} reloadTableData={reloadTableData}/>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs='12'>
          <h2>Posts</h2>
          {/* <p>Click a row to view and/or edit the post text and information.</p> */}
          <BlogPostTable reloadTable={reloadTable} />
        </Col>
      </Row>
    </Container>
  );
}

export default BlogManager;
