import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "../../../axios";
import HTMLReactParser from "html-react-parser";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function BlogPostTable() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  useEffect(() => {
    axios
      .get("/blog-posts")
      .then((response) => {
        setBlogPosts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
    <Table striped bordered hover variant="light">
      <thead>
        <tr>
          <th>Title</th>
          <th>Content</th>
          <th>Date Written</th>
          <th>Slug (url)</th>
        </tr>
      </thead>
      <tbody>
        {blogPosts.map((post) => (
          <tr className="pointer" key={post.post_id}>
            <td>{post.title}</td>
            <td>
              <Row>
                <Col xs="10" style={{ maxHeight: "40px", overflow: "hidden" }}>
                  {HTMLReactParser(post.post_body)}
                </Col>
                <Col xs="2">
                  <b>...</b>
                </Col>
              </Row>
            </td>

            <td>{new Date(post.date_written).toLocaleDateString()}</td>
            <td>{post.slug}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
  );
}

export default BlogPostTable;
