import React, { useState } from "react";
import dateformat from "dateformat";
import Swal from "sweetalert2";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../../axios";
function InvoiceTableRow({
  invoice,
  reloadTable,
  select,
  selectVendor,
  checked,
  selectBatch,
}) {
  const [showCancel, setShowCancel] = useState(true);
  const [showResend, setShowResend] = useState(false);
  const [gettingPDF, setGettingPDF] = useState(false);
  const resendEmail = (resend, isError) => {
    Swal.fire({
      icon: "question",
      title: (resend ? "Resend" : "Send") + " Email?",
      text: isError
        ? "Something went wrong the last time this was sent, do you want to attempt to send the email again?"
        : " Do you want to attempt to send the email now?",
      showCancelButton: true,
      confirmButtonText: (resend ? "Resend" : "Send") + " Invoice",
    }).then((response) => {
      if (response.isConfirmed) {
        axios.post("/email/invoice", { wi_id: invoice.wi_id }).then(() => {
          reloadTable();
          Swal.fire({
            icon: "success",
            title: "Resending Email",
            text: "Try reloading to see if the status changes.",
            timer: 8000,
          }).catch((err) =>
            Swal.fire({ icon: "error", title: "Error", text: err })
          );
        });
      }
    });
  };
  const doNotEmail = (isTrue) => {
    if (isTrue) {
      Swal.fire({
        icon: "question",
        title: "Cancel email?",
        text: "This email will be removed from the queue.",
        showCancelButton: true,
      }).then((response) => {
        if (response.isConfirmed) {
          axios
            .put("/invoice/do-not-email", {
              wi_id: invoice.wi_id,
              do_not_email: true,
            })
            .then(() => {
              reloadTable();
              Swal.fire({
                icon: "success",
                title: "Marked as 'Do Not Email'",
                timer: 2000,
              });
            });
        }
      });
    } else {
      Swal.fire({
        icon: "question",
        title: "Add to Queue?",
        text: "This email will be added to the queue.",
        showCancelButton: true,
      }).then((response) => {
        if (response.isConfirmed) {
          axios
            .put("/invoice/do-not-email", {
              wi_id: invoice.wi_id,
              do_not_email: false,
            })
            .then(() => {
              reloadTable();
              Swal.fire({
                icon: "success",
                title: "Invoice in Email Queue",
                timer: 2000,
              });
            });
        }
      });
    }
  };
  const getPDF = () => {
    Swal.fire({
      icon: "question",
      title: "Get PDF?",
      text: "You will receive the PDF version of this invoice.",
      showCancelButton: true,
      confirmButtonText: "Get PDF",
    }).then((response) => {
      if (response.isConfirmed) {
        setGettingPDF(true);
        axios({
          method: "GET",
          url: `/invoice/${invoice.wi_id}/pdf`,
          responseType: "blob",
        }).then((res) => {
          console.log("res", res);

          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("target", `_blank`);
          document.body.appendChild(link);
          link.click();

          // link.setAttribute('download', `${invoice.invoice_id}.pdf`)
          // link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          //  window.open(res.data,"_blank");
        }).catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was a problem getting the PDF. It is likely that the data uploaded was incomplete.",
          });

        })
        setGettingPDF(false);
      }
    });
  };

  return (
    invoice?.wi_id && (
      <tr>
        <td>
          <Form.Check
            checked={checked}
            onChange={() => select(invoice?.wi_id)}
          />
        </td>
        <td>{invoice?.wi_id}</td>
        <td
          style={{
            // backgroundColor: batchHex,
            textAlign: "center",
          }}
        >
          <div onClick={() => {
            selectBatch(invoice.batch_id)
          }} className="pointer rounded-3  bg-hover-light-blue">{invoice?.batch_id}</div>
        </td>
        <td> <div onClick={() => {
            selectVendor(invoice.vendor_id)
          }} className="pointer rounded-3  bg-hover-light-blue">{invoice?.vendor_name}</div></td>
        {/* <td>{invoice?.vendor_id}</td> */}
        <td className="text-center">
          {!gettingPDF ? (
            <span
              className="text-primary pointer"
              onClick={() => {
                getPDF();
              }}
            >
              <u>{invoice?.invoice_id}</u>
            </span>
          ) : (
            <Container className="main-content d-flex justify-content-center">
              <Spinner animation="grow" />
            </Container>
          )}
        </td>
        <td>{dateformat(invoice?.invoice_date, "paddedShortDate")}</td>
        <td>
          $
          {parseFloat(invoice?.total.replaceAll(",", ""))?.toLocaleString(
            "en-us",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </td>
        <td>
          {invoice?.do_not_email ? (
            <span className="pointer" onClick={() => doNotEmail(false)}>
              🚫
            </span>
          ) : invoice?.email_error ? (
            <span className="pointer" onClick={() => resendEmail(true, true)}>
              ❗
            </span>
          ) : invoice?.email_sent ? (
            <span className="pointer" onClick={() => resendEmail(true, false)}>
              ✅
            </span>
          ) : (
            <span className="pointer" onClick={() => resendEmail(false, false)}>
              ⏹️
            </span>
          )}
        </td>
        {invoice?.email_sent_date ? (
          <td
            className="pointer"
            onMouseEnter={() => setShowResend(true)}
            onMouseLeave={() => setShowResend(false)}
            onClick={() => resendEmail(true, false)}
          >
            {dateformat(invoice.email_sent_date, "paddedShortDate")}
            {showResend ? <u className="d-block text-primary">Resend?</u> : ""}
          </td>
        ) : !invoice.do_not_email ? (
          <td>
            <span>
              In Queue{" "}
              <u
                className="pointer text-danger"
                onClick={() => doNotEmail(true)}
              >
                Remove
              </u>
            </span>
          </td>
        ) : (
          <td>
            <u
              className="pointer text-primary"
              onClick={() => doNotEmail(false)}
            >
              Add to Queue
            </u>
          </td>
        )}
      </tr>
    )
  );
}

export default InvoiceTableRow;
