import React, { useState } from "react";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UploadXLSX from "./UploadXLSX";
import InvoiceTable from "./InvoiceTable";
import TableLegend from "./TableLegend";
import InvoiceTables from "./InvoiceTables";
function Invoicing() {  
   const [reloadTrigger, setReloadTrigger] = useState(false) 
  const reloadTable = () => {
    setReloadTrigger(!reloadTrigger)
  }
  return (
    <Container>
      <h2 className=''>Walmart Invoicing</h2>
      <Row className='mb-3'>
        <Col xs="12" md="7" lg="5" className='rounded-3 py-3 px-1 bg-light-grey'>
          <UploadXLSX reloadTable={reloadTable}/>
        </Col>
       
      </Row>
      <Row>
        <Col  xs="12" className='rounded-3 py-3 px- bg-light-grey'>
          <h2>Invoices</h2>
          <h5>Rows are sorted in order of the email queue.</h5>
           <span className="pointer text-primary ms-4 " style={{fontSize: "large"}} onClick={() => reloadTable()}>
          🔄️<u > Reload Tables </u>
            </span>  
          <InvoiceTables reloadTable={reloadTable} reloadTrigger={reloadTrigger}  />
          <TableLegend />
        </Col>
      </Row>
    </Container>
  );
}

export default Invoicing;
