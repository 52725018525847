import React from "react";

import { Routes, Route } from "react-router-dom";
import Landing from "./Components/Landing/Landing";
import General from "./Components/General/General";
import ContactPage from "./Components/Contact/ContactPage";
import About from "./Components/About/About";
import EmailInstructions from "./Components/Email/EmailInstructions";
import EmailLogin from "./Components/Email/EmailLogin";
import AdminLogin from "./Components/Admin/AdminLogin";
import Admin from "./Components/Admin/Admin";
import AccountManager from "./Components/Admin/Accounts/AccountManager";
import BlogPostPage from "./Components/BlogPost/BlogPostPage";

// import MyMiniFactoryScraper from "./Components/Admin/MyMiniFactoryScraper";

export default (
  <Routes>
    <Route exact path="/" element={<Landing />} />
    <Route exact path="/general-deduction-recovery" element={<General />} />
    <Route exact path="/contact" element={<ContactPage />} />
    <Route exact path="/team" element={<About />} />
    <Route exact path="/email/instructions" element={<EmailInstructions />} />
    <Route exact path="/email/" element={<EmailLogin />} />
    <Route exact path="/admin-login" element={<AdminLogin />} />
    <Route exact path="/admin" element={<Admin />} />
    <Route exact path="/article/:slug" element={<BlogPostPage />} />
    <Route exact path="/account-manager" element={<AccountManager />} />
    {/* <Route exact path="/mmf" element={<MyMiniFactoryScraper />} /> */}
  </Routes>
);
