import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import Spinner from "react-bootstrap/Spinner";
function BlogPostPage() {
  const { slug } = useParams();
  const [postData, setPostData] = useState({});
  const getPostData = () => {
    if (!slug) return console.log("no slug");
    axios.get(`/blog-post/${slug}`).then((res) => {
      console.log(res.data);
      setPostData(res.data);
    });
  };
  useEffect(() => {
    getPostData();
  }, [slug]);

  return postData.title ? (
    <Container>
      <Row>
        <Col className='text-center my-5'>
          <h1>{postData.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs="12">{HTMLReactParser(postData.post_body || "")}</Col>
      </Row>
    </Container>
  ) : (
    <Container className="main-content d-flex justify-content-center">
      <Spinner animation="grow" />
    </Container>
  );
}

export default BlogPostPage;
