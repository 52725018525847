import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "../../../axios";
import Swal from "sweetalert2";
function AddClientModal({ setModal, show, reloadAll }) {
  const [state, setState] = useState({
    client_name: "",
    // email_alias: "",
    // login_phone: ""
  });
  console.log('state', state)
  const handleSubmit = async(e) => {
    e.preventDefault()
    await axios.post('/client',state)
    reloadAll()
    setModal(false)
    Swal.fire({icon: 'success', title: "Client added!", timer: 1000, showConfirmButton: false })
  }
  return (
    <Modal
      onHide={() => setModal(false)}
      size="lg"
     
      show={show}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Client
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
       
          <Form.Group>
            <Form.Label>Client Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                setState({ ...state, client_name: e.target.value })
              }
              value={state.client_name}
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Account Email (Optional)</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                setState({ ...state, email_alias: e.target.value })
              }
              value={state.email_alias}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Account Phone (Optional) Do not include +1 at the beginning</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                setState({ ...state, login_phone: e.target.value })
              }
              value={state.login_phone}
            />
          </Form.Group> */}
          <div>
            <Button className="float-end mt-3" type='submit'>Add Client</Button>
          </div>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setModal(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddClientModal;
