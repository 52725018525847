import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PhoneMessages from "./PhoneMessages";
import { useEffect } from "react";
import axios from "../../../axios";
import Swal from "sweetalert2";
function Client({ client, reloadAll }) {
  console.log("client", client);
  const [editMode, setEditMode] = useState(false);
  const [clientName, setClientName] = useState("");
  useEffect(() => {
    setClientName(client.client_name);
  }, [client.client_name]);
  const handleSubmit = () => {
    if (clientName?.length > 0 && clientName !== client.client_name) {
      axios
        .put("/client", {
          client_id: client.client_id,
          client_name: clientName,
        })
        .catch((err) => console.log("err", err));
      reloadAll();
      Swal.fire({
        icon: "success",
        title: "Client name updated.",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      icon: "warning",
      title: "Delete this client?",
      text: "This will not delete any phone numbers, emails, or messages it was associated with. They will remain with no client association.",

      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete("/client/" + client.client_id);
        reloadAll();
        Swal.fire({
          icon: "success",
          title: "Client deleted.",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    });
  };
  return (
    <Container>
      <u
        className="text-primary float-end pointer"
        onClick={() => {
          setEditMode(!editMode);
        }}
      >
        {editMode ? "Cancel" : "Edit"}
      </u>
      <div>
        {editMode ? (
          <>
            <Form.Group className="">
              <Form.Label>Client Name</Form.Label>

              <Form.Control
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
              <Button className="my-3 " onClick={() => handleSubmit()}>
                Submit Change
              </Button>
              <u
                className="text-secondary po;inter float-end my-3  "
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </u>
            </Form.Group>
          </>
        ) : (
          <h3>{client.client_name}</h3>
        )}
      </div>
      <div>
        <h5>
          Phone: {client.login_phone ? client.login_phone : "None associated."}{" "}
        </h5>

        <h5>
          Email: {client.email_alias ? client.email_alias : "None associated."}{" "}
        </h5>
      </div>
      {client.login_account_id && (
        <PhoneMessages login_account_id={client.login_account_id} />
      )}
    </Container>
  );
}

export default Client;
