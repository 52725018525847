import React from 'react'
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
function TableLegend() {
  return (
    <Row className='rounded-3 bg-light my-3 mx-1 py-2'>
        <h5>Table Legend</h5>
        <div className='text-secondary mb-2'>Try clicking on these icons in each row to reverse their status or find other options.</div>
        <Col xs='2'>
            <span>⏹️</span>
        </Col>
        <Col xs='9'>In the queue to be emailed.</Col>
        <Col xs='2'>
            <span>✅</span>
        </Col>
        <Col xs='9'>Emailed without error.</Col>
        <Col xs='2'>
            <span>🚫</span>
        </Col>
        <Col xs='9'>Not in the queue.</Col>
        <Col xs='2'>
            <span>❗</span>
        </Col>
        <Col xs='9'>Error when sending the email. </Col>
        <Col xs='2'>
            <span>Batch</span>
        </Col>
        <Col xs='9'>Group of invoices uploaded in the same file. Click to select the whole batch. </Col>
        <Col xs='2'>
            <span>Vendor Name</span>
        </Col>
        <Col xs='9'> Click to select all invoices from this vendor. </Col>
        <Col xs='2'>
            <span>Invoice 📄</span>
        </Col>
        <Col xs='9'> Click to get the PDF of the invoice. </Col>
    </Row>
  )
}

export default TableLegend