import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import axios from "../../axios";
import { useUser } from "../../UserContext";
import { useNavigate } from "react-router";
function AdminLogin() {
  let navigate = useNavigate();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({status: 0});
  const [validated, setValidated] = useState(false);
  let user = useUser();
  useEffect(
    () => {
      if(user.currentUser?.user_id ){
        navigate('/admin')
      }
    },[user])
  console.log('error', error)
  const login = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      axios
        .post("/auth/login", {
          ...state,
          username: state.username.replaceAll(" ", "").toLowerCase(),
        })
        .then(async (res) => {
          await user.setCurrentUser(res.data);

          if (res.data.user_id) {
            navigate("/admin");
          }
        })

        .catch((err) => {
          console.log("err", err.response, err);
          setError(err.response);
        });
    }
  };

  const inputHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Row>
      <Col xs="12" md="5" className="mx-auto">
        <Container className="p-0">
          <Form
            noValidate={true}
            validated={validated}
            onSubmit={login}
            className="login-form mx-auto  p-4 rounded-3"
          >
            <Form.Group className="mb-2">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="you@username.com"
                name="username"
                value={state.username}
                onChange={inputHandler}
              />
            </Form.Group>
            {error.status && error.status === 400 ? (
              <Alert variant="danger">{error.data}</Alert>
            ) : null}
            <Form.Group className="mb-2">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="********"
                required
                name="password"
                value={state.password}
                onChange={inputHandler}
              />
            </Form.Group>
            {error.status && error.status === 401 ? (
              <Alert variant="danger">{error.data}</Alert>
            ) : null}
            <Row>
              <Button
                className="my-2 mx-auto w-25"
                type="submit"
                variant="primary"
              >
                LOGIN
              </Button>
            </Row>
          </Form>
        </Container>
      </Col>
    </Row>
  );
}

export default AdminLogin;
