import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import blue_bg from "../../images/blue_bg.png";
import "./Landing.css";
import Contact from "../Contact/Contact";
import { useNavigate } from "react-router";
function Landing() {
  let navigate = useNavigate();
  return (
    <div>
      <Row>
        <Col className="text-center my-3">
          <h1 className="my-5 ">WE HELP WITH MONEY RECOVERY FOR WALMART SUPPLIERS</h1>
          <p className="my-3">
            We are experts in Walmart deduction recovery. As such, we know how
            to recover deductions in fast, efficient and professional ways and
            have recovered millions of dollars for our clients. Is your team
            leaving money on the table? We’ll help you find it—and, more
            importantly, we’ll help you get it back.
          </p>
          <Button
            onClick={() => navigate("/contact")}
            className="btn-maroon my-3"
          >
            GET MONEY BACK
          </Button>
        </Col>
      </Row>
      <Row className="my-3">
        <h2 className="text-center my-3">HOW YOU BENEFIT</h2>
        <Col
          xs="12"
          lg="4"
          className="d-flex flex-column align-items-center justify-content-end"
        >
          <img
            loading="lazy"

            className="w-75"
            src="/images/save.png"
            alt="icons showing clock, energy bolt, and smile with arrows pointing up for each."
          />
          <h3 className="text-center my-3">
            Save Your Team Time, Energy and Frustration
          </h3>
          <p className="text-center my-3">
            Our team will handle the entire Walmart deduction recovery process
            so your team can spend their time and talents on other projects.
          </p>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="d-flex flex-column align-items-center justify-content-end"
        >
          <img
            loading="lazy"

            className="w-75"
            src="/images/money_back.png"
            alt="Red bar graph showing small bar and larger bar next to each other."
          />

          <h3 className="text-center my-3">
            Get More Money Back Than You Otherwise Would
          </h3>
          <p className="text-center my-3">
            Using the thorough and professional approach we take to recover
            every possible deduction, we’re able to recover funds that would
            otherwise slip through the cracks.
          </p>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="d-flex flex-column align-items-center justify-content-end"
        >
          <img
            loading="lazy"

            className="w-75"
            src="/images/shake_hands.png"
            alt="Icon showing hand shake."
          />

          <h3 className="text-center my-3">
            Strengthen Your Business Relationship With Walmart
          </h3>
          <p className="text-center my-3">
            You will strengthen your relationship with Walmart by taking the
            initiative to ensure that all of your transactions are transparent
            and fair.
          </p>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center">
        <h2 className="text-center my-5">HOW OUR PROCESS WORKS</h2>
        <Col xs="12" md="8 ">
          <Row className="my-3">
            <Col xs="12" sm="6">
              <img
            loading="lazy"

                src="/images/sign.png"
                className="w-100"
                alt="icon showing a feather quill signing a paper."
              />
            </Col>
            <Col xs="12" sm="6">
              <h3>1. You Agree to Let Us Help You</h3>
              <p>
                The setup process is easy: All we need from you is to sign a
                consultation agreement and help us gather some initial
                information. The agreement is simple, transparent and easy to
                understand, with no hidden fees or agendas. We can gather the
                information in only a few minutes.
              </p>
            </Col>
          </Row>
          <Row className="my-3">
            <Col xs="12" sm="6">
              <img
            loading="lazy"

                src="/images/hand_off.png"
                className="w-100"
                alt="icon showing a feather quill signing a paper."
              />
            </Col>
            <Col xs="12" sm="6">
              <h3>2. We Take It From There</h3>

              <p>
                Once we have the information we need, our talented team will do
                the rest. We’ll use methods that we developed through years of
                experience to recover as many deductions as possible for you,
                with little to no work on your end.
              </p>
            </Col>
          </Row>
          <Row className="my-3">
            <Col xs="12" sm="6">
              <img
            loading="lazy"

                src="/images/money_back_cycle.png"
                className="w-100"
                alt="icon showing a feather quill signing a paper."
              />
            </Col>
            <Col xs="12" sm="6">
              <h3>3. You Get Money Back</h3>
              <p>
                Our team is always able to recover some deductions, so you’re
                guaranteed to get money back. Additionally, we don’t get paid
                until you do (we only take a percentage of the money that is
                recovered), making the entire process risk-free.
              </p>
            </Col>
            <Row className="my-3">
              <Col className="text-center">
                <Button
                  onClick={() => navigate("/contact")}
                  className="btn-maroon"
                >
                  GET STARTED TODAY
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center my-5">
        <h2 className="text-center">WHY YOU NEED OUR HELP</h2>
        <Col
          xs="12"
          md="10"
          className="text-light my-2 p-4"
          style={{
            backgroundImage: `url('${blue_bg}')`,
            backgroundPosition: "top",
          }}
        >
          <h3>
            <b>Walmart Deduction Mistakes Will Always Happen</b>
          </h3>
          <p className="my-3">
            While doing business with Walmart, errors are bound to happen during
            the shipping and delivery process. Consequently, companies often
            lose money that they are owed.
          </p>
          <p>
            Our experienced team is able to recognize and recover deductions
            that would otherwise slip through the cracks. During the process, we
            make sure your company is fairly compensated for the goods you have
            delivered to Walmart.
          </p>
        </Col>
        <Col
          xs="12"
          md="10"
          className="text-light my-2 p-4 "
          style={{
            backgroundImage: `url('${blue_bg}')`,
            backgroundPosition: "bottom",
          }}
        >
          <h3>
            <b>The Walmart Deduction Recovery Process Is Difficult</b>
          </h3>
          <p className="my-3">
            Effectively recovering deductions from Walmart is a skill that takes
            time and experience to master. As a result, even the most
            experienced accounting teams frequently miss deductions that could
            otherwise be returned. Even if your team already works to recover
            deductions from Walmart, there is likely more money waiting for you.
          </p>
          <p>
            Our team is familiar with the specific processes and procedures that
            Walmart follows, which is how we are able to recover the maximum
            amount of money for you.
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/biz_man.png"
            alt="Sillhouette of business man."
          />
          <h3 className="text-center my-3">
            We’re Professional and Experienced
          </h3>
          <p className="text-center">
            Our team has years of experience recovering deductions from Walmart.
            We are familiar with the most common deductions and mistakes and how
            to recognize them. We also know how to go about recovering them in
            the most efficient, effective and professional way.
          </p>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/shake_hands_2.png"
            alt="Different icon of shaking hands."
          />
          <h3 className="text-center my-3">We’re Committed to Your Recovery</h3>
          <p className="text-center">
            Our mission is to empower your team to conduct business with Walmart
            in a way that equally benefits both parties. Your company likely
            deserves to receive money back due to common mistakes made during
            the shipping and delivery process. If it does, we will recover it
            for you.
          </p>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/fast.png"
            alt="Icon of stop watch with lines resembling speed."
          />
          <h3 className="text-center my-3">We’re Fast and Efficient</h3>
          <p className="text-center">
            Our methods enable us to shorten the deduction recovery timeline
            from many months to only a few weeks. In some cases, you can be
            compensated for years of deduction mistakes in as little as three
            weeks.
          </p>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/trusted.png"
            alt="Icon of shield shape with a check mark in it."
          />
          <h3 className="text-center my-3">We’re Trusted by Trusted Brands</h3>
          <p className="text-center">
            All of our customers are satisfied with the work we do. In fact,
            many of them are willing to attest to the speed, professionalism and
            quantity of our recoveries.
          </p>
        </Col>
        <Col xs="12" className="text-center">
          <Button onClick={() => navigate("/about")} className="btn-maroon">
            MEET YOUR NEW TEAM
          </Button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="8">
          <Contact />
        </Col>
      </Row>
      
    </div>
  );
}

export default Landing;
