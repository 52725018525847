import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import AccountList from "./AccountList";
import Account from "./Account";
import AddClientModal from "./AddClientModal";
import ClientList from "./ClientList";
import Client from "./Client";
import axios from "../../../axios";
import AddPhoneModal from "./AddPhoneModal";
import { useNavigate } from "react-router";
function AccountManager() {
  let navigate = useNavigate("/accounts");
  const [currentAccount, setCurrentAccount] = useState({ login_account_id: 0 });
  const [currentClient, setCurrentClient] = useState({ client_id: 0 });
  const [reload, setReload] = useState(false);
  const reloadAll = () => {
    setReload(!reload);
    setCurrentAccount({ login_account_id: 0 });
    setCurrentClient({ client_id: 0 });
  };

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showAddPhoneModal, setShowAddPhoneModal] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClients = async () => {
      let clientResults = await axios.get("/clients");
      console.log(clientResults);
      setClients(clientResults.data);
    };
    const authenticate = async () => {
      const {data} = await axios.get('/auth/user')
      console.log('user data', data)
      if(data?.user_id > 0){

        getClients();
      } else {
        navigate("/accounts")
      }
    }
    authenticate()

  }, [reload]);
  const logout = async () => {
    await axios.post("/auth/logout");
    navigate("/");
  };
  return (
    <Container>
      {/* <Row className="my-4">
        <Col>
        <h2>Actions</h2>
        <Button onClick={() => setShowAddClientModal(true)}>
        Add a Client
        </Button>
        </Col>
      </Row> */}
<div className="d-flex justify-content-end">
      <div className="w-25 text-end">

        <u className="text-secondary pointer " onClick={() => logout()}>
          Logout
        </u>
</div>
      
      </div>
        <h2>Client Email and Phone Numbers for Retail Link</h2>
      <hr />
      <Row>
        <Col
          xs="12"
          sm="4"
          lg="3"
          className="account-manager-section border-right"
        >
          <h2 >Phone and Email</h2>
          <Button className="my-3" onClick={() => setShowAddPhoneModal(true)}>
            Add a Phone Number
          </Button>
          <AccountList
            reload={reload}
            clients={clients}
            setCurrentAccount={setCurrentAccount}
            currentAccount={currentAccount}
          />
        </Col>
        <Col>
          {currentAccount.login_account_id > 0 && (
            <Account
              reloadAll={reloadAll}
              clients={clients}
              currentAccount={currentAccount}
            />
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col
          xs="12"
          sm="4"
          lg="3"
          className="account-manager-section border-right"
        >
          <h2>Clients</h2>
          <Button className="my-3" onClick={() => setShowAddClientModal(true)}>
            Add a Client
          </Button>

          <ClientList
            clients={clients}
            currentClient={currentClient}
            setCurrentClient={setCurrentClient}
          />
        </Col>
        <Col>
          {currentClient.client_id > 0 && (
            <Client
              reload={reload}
              reloadAll={reloadAll}
              client={currentClient}
            />
          )}
        </Col>
      </Row>
      <AddClientModal
        show={showAddClientModal}
        reload={reload}
        reloadAll={reloadAll}
        setModal={setShowAddClientModal}
      />{" "}
      <AddPhoneModal
        show={showAddPhoneModal}
        reload={reload}
        reloadAll={reloadAll}
        setModal={setShowAddPhoneModal}
      />
    </Container>
  );
}

export default AccountManager;
