import React, { useEffect, useState } from "react";

import Table from "react-bootstrap/Table";
import axios from "../../../axios";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import InvoiceTableRow from "./InvoiceTableRow";
function InvoiceTable({ loaded, type, reloadTable, invoices }) {
  // const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  // const [loaded, setLoaded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [vendorInput, setVendorInput] = useState("");

  useEffect(() => {
    if (selectAll) {
      let newArr = invoices.map((inv) => inv.wi_id);
      setSelected(newArr);
    } else {
      setSelected([]);
    }
  }, [selectAll]);
  const filterByInput = () => {
    setSelected([]);
    if (!vendorInput) {
      setFilteredInvoices(invoices);
      return;
    }
    let lowerCase = vendorInput.toLowerCase();
    let newArr = [...invoices];
    newArr = newArr.filter((vendor) =>
      vendor?.vendor_name.toLowerCase()?.includes(lowerCase)
    );
    setFilteredInvoices(newArr);
  };
  useEffect(() => {
    filterByInput();
  }, [vendorInput, invoices]);
  const select = (id) => {
    let newArr = [...selected];
    if (newArr.includes(id)) {
      newArr = newArr.filter((inv) => inv !== id);
    } else {
      newArr = [...newArr, id];
    }
    setSelected(newArr);
  };
  const selectBatch = (batchId) => {
    let batchInvoiceIds = invoices.filter((inv) => inv.batch_id === batchId);
    batchInvoiceIds = batchInvoiceIds.map((inv) => inv.wi_id);
    setSelected(batchInvoiceIds);
  };
  const selectVendor = (vendorId) => {
    let vendorIds = invoices.filter((inv) => inv.vendor_id === vendorId);
    vendorIds = vendorIds.map((inv) => inv.wi_id);
    setSelected(vendorIds);
  };
  const addSelectedToQueue = () => {
    Swal.fire({
      icon: "question",
      title: "Add Eligible to Queue?",
      text: "This will only add selected invoices that are not in the queue. This will not included invoices that have already been sent.",
      showCancelButton: true,
      confirmButtonText: "Add to Queue",
    }).then((decision) => {
      if (decision.isConfirmed) {
        //axios
        axios
          .put("/invoice/do-not-email", {
            wi_id: null,
            do_not_email: false,
            id_array: selected,
          })
          .then(() => {
            reloadTable();
            Swal.fire({
              icon: "success",
              title: "Added to Queue",
              timer: 2000,
            });
          });
        reloadTable();
      }
    });
  };
  const removeSelectedFromQueue = () => {
    Swal.fire({
      icon: "question",
      title: "Remove Eligible from Queue?",
      text: "This will only remove selected invoices that are currently in the queue. This will not included invoices that have already been sent.",
      showCancelButton: true,
      confirmButtonText: "Remove from Queue",
    }).then((decision) => {
      if (decision.isConfirmed) {
        axios
          .put("/invoice/do-not-email", {
            wi_id: null,
            do_not_email: true,
            id_array: selected,
          })
          .then(() => {
            reloadTable();
            Swal.fire({
              icon: "success",
              title: "Removed from Queue",
              timer: 2000,
            });
          });
        reloadTable();
      }
    });
  };
  const deleteSelected = () => {
    if (selected.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "None selected.",
        text: "Nothing to delete.",
        timer: 3000,
      });
      return;
    }
    Swal.fire({
      icon: "question",
      title: "Delete Selected Invoices?",
      text: "This will delete all selected invoices. This action cannot be undone.",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((decision) => {
      if (decision.isConfirmed) {
        axios.delete('/invoices', { 
          params: { 
            id_array: selected || []
          } 
        })
          .then(() => {
            reloadTable();
            Swal.fire({
              icon: "success",
              title: "Deleted.",

              timer: 2000,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Nothing to delete.",
              timer: 3000,
            })
            })
    
            
          
      }
    });
  };

  return (
    <div>
      {type === "queue" && (
        <div className="my-3">
          <h4>Email Queue: </h4>
          
          <Button
            className="btn-sm  "
            disabled={selected?.length < 1}
            onClick={() => removeSelectedFromQueue()}
          >
            Remove Selected from Queue
          </Button>
        </div>
      )}
      {type === "sent" && (
        <div className="my-3">
          <h4>Sent Invoices: </h4>
        </div>
      )}
      {type === "unsent" && (
        <div className="my-3">
          <h4>Unsent Invoices: </h4>
          <Button
            className="btn-sm me-3"
            disabled={selected?.length < 1}
            onClick={() => addSelectedToQueue()}
          >
            Add Selected to Queue {selected?.length > 0 && `(${selected?.length})`}
          </Button>

        </div>
      )}
      <Row className="px-1">
        <Col className="d-flex align-items-center">
          <u
            className="pointer text-primary d-block"
            onClick={() => setSelectAll(true)}
          >
            Select All
          </u>

          <u
            className="pointer text-danger ms-2 d-block "
            onClick={() => {
              setSelectAll(false);
              setSelected([]);
            }}
          >
            Deselect All
          </u>

          <u
            className="pointer text-secondary ms-2 d-block "
            onClick={() => {
              deleteSelected();
              // setSelected([]);
            }}
          >
            Delete Selected {selected?.length > 0 && `(${selected?.length})`}
          </u>
        </Col>
        <Col xs="12" md="6">
          <Form.Group className="d-flex align-items-center mb-3">
            <Form.Control
              type="text"
              placeholder="Search for a Vendor"
              value={vendorInput}
              onChange={(e) => setVendorInput(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <div style={{ maxHeight: "500px", overflow: "scroll" }}>
        <Table hover striped bordered variant="light">
          <thead>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <th>
                <Form.Check
                  checked={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                />
              </th>
              <th>ID</th>
              <th>Batch</th>
              <th>Vendor Name</th>
              {/* <th >Vendor ID</th> */}
              <th>Invoice 📄</th>
              <th>Invoice Date</th>
              <th>Total</th>
              <th colSpan={2}>Status</th>
            </tr>
          </thead>
          <tbody>
            {invoices?.length > 0 && loaded ? (
              filteredInvoices &&
              filteredInvoices?.map((invoice, index, array) => {
                let batchHex = "#fff";
                let checked = selected.includes(invoice.wi_id);
                return (
                  <InvoiceTableRow
                    key={invoice.wi_id}
                    select={select}
                    selectBatch={selectBatch}
                    selectVendor={selectVendor}
                    checked={checked}
                    batchHex={batchHex}
                    invoice={invoice}
                    reloadTable={reloadTable}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>
                  {!loaded ? (
                    <Container className="main-content d-flex justify-content-center">
                      <Spinner animation="grow" />
                    </Container>
                  ) : (
                    <em>No invoices found.</em>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default InvoiceTable;
