import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "../../../axios";
import Swal from "sweetalert2";
import Checkbox from "../../UIComponents/Checkbox";

function BlogEditor({ setShowBlogForm, reloadTable, postData }) {
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [published, setPublished] = useState(false);
  const submit = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      axios
        .post("/blog-post", {
          title: title,
          content: editorRef.current.getContent(),
          slug: slug,
          published: published,
        })
        .then((res) => {
          reloadTable();
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Blog post submitted successfully.",
            timer: 1000,
          });
          setShowBlogForm(false);
        });
    }
  };

  return (
    <div>
      <Form.Group>
        <Form.Group className="my-3">
          <Checkbox
            handleClick={() => setPublished(!published)}
            label={"Publish this post."}
            checked={published}
            disabled={false}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group controlId="blogTitle" className="my-3">
        <Form.Label>Blog Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter blog title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="blogSlug" className="my-3">
        <Form.Label>URL Slug</Form.Label>
        <em className="d-block">This is the last part of the url. </em>
        <em className="d-block">
          For example: walmart-recovery would result in
          retailconsultingteam.com/walmart-recovery.
        </em>
        <b>It must be unique to this post.</b>
        <Form.Control
          type="text"
          placeholder="Enter the url slug without spaces"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
          pattern="/S+/"
        />
      </Form.Group>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue=""
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen image link ",
            "insertdatetime media table paste   wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat |  help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <Button className="mt-5 float-end" onClick={() => submit()}>
        Submit Blog Content
      </Button>
    </div>
  );
}

export default BlogEditor;
