import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router";
import "./General.css";
import Contact from "../Contact/Contact";
function General() {
  let navigate = useNavigate();
  return (
    <div>
      <Row className="d-flex flex-column align-items-center">
        <Col xs="12" md="8" lg="9">
          <h1 className="text-blue mt-5 mb-3 text-center">
            WE’LL TEACH YOU ABOUT GENERAL DEDUCTION RECOVERY
          </h1>
          <p className="text-center">
            Regardless of which retailers you’re working with, deductions are a
            natural and expected part of the shipping and delivery process. We
            want to teach you how to recognize, prevent and recover as many
            deductions as possible to maximize your profits and improve your
            bottom line.
          </p>
        </Col>
        <Col xs="12" className="text-center my-3">
          <Button className="btn-maroon" onClick={() => navigate("/contact")}>
            GET STARTED TODAY
          </Button>
        </Col>
      </Row>
      <Row>
        <h2 className="text-center mt-5">WHAT YOU’LL LEARN</h2>
        <Col
          xs="12"
          lg="4"
          className="d-flex flex-column align-items-center justify-content-end"
        >
          <img
            loading="lazy"

            className="w-75"
            src="/images/recognize.png"
            alt="icons showing clock, energy bolt, and smile with arrows pointing up for each."
            
          />
          <h3 className="text-center my-3">
            How to Recognize When and Why Deductions Happen
          </h3>
          <p className="text-center my-3">
            Understanding when and why deductions happen is an important first
            step. With this knowledge, fewer deductions will slip through the
            cracks.
          </p>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="d-flex flex-column align-items-center justify-content-end"
        >
          <img
            loading="lazy"

            className="w-75"
            src="/images/reduce_bar_chart.png"
            alt="Red bar graph showing small bar and larger bar next to each other."
            

          />

          <h3 className="text-center my-3">
            How to Prevent and Reduce Future Deductions
          </h3>
          <p className="text-center my-3">
            Once you understand why deductions happen, we’ll teach you what you
            can do to avoid as many as possible. When it comes to deductions,
            prevention is better than cure.
          </p>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="d-flex flex-column align-items-center justify-content-end"
        >
          <img
            loading="lazy"

            className="w-75"
            src="/images/quickly.png"
            alt="Icon showing hand shake."
          />

          <h3 className="text-center my-3">
            How to Recover Deductions Quickly and Successfully
          </h3>
          <p className="text-center my-3">
            We’ll teach you how to determine which deductions are possible to
            recover. Afterward, we’ll teach you how to recover them in fast,
            efficient and successful ways.
          </p>
        </Col>
      </Row>
      <Row className=" d-flex flex-column align-items-center">
        <h2 className="text-blue text-center my-5 ">
          HOW OUR DEDUCTION RECOVERY CONSULTATIONS WORK
        </h2>
        <Col xs="12" md="8">
          <ol>
            <li className="my-2">You Agree to Let Us Help You</li>
            <p>
              This step is simple: All we need from you is to sign a
              consultation agreement. Because the agreement is on a
              month-to-month basis, you won’t need to worry about signing any
              long-term contracts.
            </p>
            <li className="my-2">We Show You The Ropes</li>
            <p>
              Our mission is simple: to give you the skills and knowledge
              necessary to recover deductions independently. In order to do so,
              we’ll share the wisdom we’ve gained through years of experience
              and research. We’ll teach you when and why deductions happen, how
              to prevent and reduce future deductions and how to recover them
              quickly when they do happen.
            </p>
            <li className="my-2">You Take It From There</li>
            <p>
              After our consultation meetings, you’ll be more prepared to
              recover your own deductions. With your new skills and knowledge,
              we’re confident that your deduction recovery efforts will be much
              more successful. Not only will you recover more deductions, but
              you’ll receive more money back from those deductions than you
              otherwise would have.
            </p>
            <li className="my-2">We Follow Up With You</li>
            As you work to recover deductions, we will follow up with you to
            ensure your success. We will analyze your recovery efforts, compare
            your results to industry standards and share our feedback. Whenever
            you need advice, have questions, or want our team of experts to
            oversee your progress, we’ll be there.
          </ol>
        </Col>
        <Col xs="12" className="text-center">
          <h5 className="text-blue my-3">We're Ready to Help You.</h5>
          <Button className="btn-maroon mb-5" onClick={() => navigate("/contact")}>
            GET STARTED TODAY
          </Button>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center">
        <h2 className="my-5 text-center text-blue">WHY YOU NEED US</h2>
        <Col className="bg-blue my-3 text-light p-4" xs="12" md="10" lg="8">
          <h3>
            <b>Shipping and Delivery Deductions Are Inevitable</b>
          </h3>
          <p className="ms-4 mt-2">
            Deductions always happen during the shipping and delivery process.
            Consequently, it’s important that companies understand how to
            recognize and deal with deductions to prevent leaving money on the
            table. In order to maximize profits, you’ll need expert help to
            learn how to handle deductions in the best ways.
          </p>
        </Col>
        <Col className="bg-blue my-3 text-light p-4" xs="12" md="10" lg="8">
          <h3>
            <b>The Deduction Recovery Process is Difficult</b>
          </h3>
          <p className="ms-4 mt-2">
            Deduction recovery is a specific skill that takes years to master.
            In the meantime, a lot of money will be lost, making it an expensive
            skill to learn. However, with the help of experienced professionals
            like us, the learning curve can be drastically reduced. We can help
            you become experts in deduction recovery quickly, enabling you to
            independently recover the money you are owed.
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <h2 className='text-center text-blue my-5'>CONSULTANTS YOU CAN TRUST</h2>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/calendar.png"
            alt="Calendar icon."
          />
          <h3 className="text-center my-3">We Have Years of Experience</h3>
          <p className="text-center">
            We want you to benefit from our wisdom. Because we have spent years
            researching the deduction recovery process, there’s no need for you
            to start from scratch. We can teach you everything you need to know
            so that you can recover your deductions on your own.
          </p>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/shake_hands_2.png"
            alt="Different icon of shaking hands."
          />
          <h3 className="text-center my-3">
            We Have Your Best Interests at Heart
          </h3>
          <p className="text-center">
            Our only goal is to help you minimize your deductions and maximize
            your profits. No long-term contracts; no hidden fees; no strings
            attached. As you use the skills we teach you to recover your own
            deductions, your success is our success.
          </p>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/adapt.png"
            alt="Icon of stop watch with lines resembling speed."
          />
          <h3 className="text-center my-3">We’re Fast and Efficient</h3>
          <p className="text-center">
            Because we understand the deduction recovery process so well, we are
            able to adapt our consultation sessions to meet your needs. We don’t
            follow pre-written lesson plans. Instead, we find out what your
            company’s unique needs are and enable you to fulfill them.
          </p>
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex flex-column align-items-center justify-content-start my-3"
        >
          <img
            loading="lazy"

            className="w-25 "
            src="/images/trusted.png"
            alt="Icon of shield shape with a check mark in it."
          />
          <h3 className="text-center my-3">We’re Trusted by Trusted Brands</h3>
          <p className="text-center">
            We are proud to say that our clients are willing to attest to the
            value they receive from our general deduction recovery
            consultations. The work we do has proven to be very effective in
            helping companies like yours recover money they otherwise would have
            lost.
          </p>
        </Col>
        <Col xs="12" className="text-center">
          <Button onClick={() => navigate("/about")} className="btn-maroon">
            MEET THE TEAM
          </Button>
        </Col>
      </Row>
      <Contact />
    </div>
  );
}

export default General;
