import React from "react";

import "./About.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { team } from "../../constants.js";


function About() {


  return (
    <>
    {team.map((item, index) => (
    <Row className="d-flex justify-content-center my-5" key={index}>
      <Col  xs="8" md="4" lg="3" className='pt-1'>
        <img
          className="about-img"
          src={item.imageSrc}
          alt={item.alt}
          loading="lazy"
        />
      </Col>
      <Col className='pt-0'>
        <h2 className="text-blue name text-align-top">{item.name?.toUpperCase()}</h2>
        <h4 className="text-secondary title ">{item.title?.toUpperCase()}</h4>
        {item.desc.map((paragraph, i) => (
          <p key={i} className="desc">{paragraph}</p>
        ))}
      </Col>
    </Row>
  ))}
      
    </>
  );
}

export default About;
