export const team = [
  {
    name: "ROBERT CRAWLEY",
    title: "PRESIDENT, OWNER",
    desc: [
      "Robert is an expert in accounting and entrepreneurship—two skills that have served him well in his life. With a master's degree in accounting, Robert has filled various roles and started multiple companies throughout his career. Driven by a desire to support his family and take control of his work life, he started Retail Consulting Team and has worked hard to make it successful.",
      " After fighting the deduction battle for 5 years at a Walmart supplier, Rob 'cracked the code' and has become an expert at recognizing deductions that can be recovered and effectively recovering them for suppliers. Together with his trusted team, he has made the Walmart deduction recovery process simple, easy, and effective and has recovered tens of millions of dollars for Walmart suppliers to date. His life's mission is to help others in every way that he can, and he is waiting to help your company receive the money it is owed by Walmart.",
    ],
    imageSrc: "/images/team/Rob.jpg",
    alt: "Rob Crawley Portrait",
  },
  {
    name: "ANDREA CRAWLEY",
    title: "VICE PRESIDENT, OWNER",
    desc: [
      "Andrea and Rob have been married for nearly 25 years. The couple has always worked well together and has started multiple successful companies, both independently and together. Accordingly, when the opportunity arose to start Retail Consulting Team, Andrea devoted her time and talents to help make it successful. Motivated by a desire to provide for her family and help businesses earn more money, Andrea is always taking on new responsibilities. ",
      "As the Vice President, she helps with day-to-day operations; interacts with old, new, and potential customers on behalf of the company; manages internal logistics; helps to develop the business strategy; manages Human Resources and more—and that's on a slow day!",
    ],
    imageSrc: "/images/team/Andrea.jpeg",
    alt: "Andrea Crawley Portrait",
  },
  {
    name: "NATHAN WAGNER",
    title: "VICE PRESIDENT OF FINANCE",
    desc: [
      "Nathan's accounting career began when he was hired by Rob as a General Accountant for BetterBody Foods. After demonstrating a strong work ethic and impressive accounting talent, Nathan was quickly promoted to Controller. As the Controller, Nathan was on a quest to recover deductions from Walmart and many other suppliers.",
      "He became proficient at analyzing and recovering deductions of all kinds. When Rob started Retail Consulting Team, he reached out to Nathan specifically to hire him. Holding an MBA with an emphasis in accounting, Nathan handles most deduction recoveries and is the one our clients thank when they receive thousands of dollars back from Walmart.",
      "His dedication, reliability, and excellent communication skills help make the deduction recovery process fast, effective, and easy for all of our clients.",
    ],
    imageSrc: "/images/team/Nathan.jpeg",
    alt: "Nathan Wagner Portrait",
  },
  {
    name: "SAM APPLEGATE",
    title: "SENIOR ACCOUNT MANAGER",
    desc: [
      "Sam has over 35 years of sales and management experience. With a natural talent for listening and connecting with people on a personal level, she has found her career to be both successful and rewarding.",
      "Over the years, she has consistently been recognized as the top sales representative for the organizations she has helped to grow. Motivated by a desire to help others, she only works for reputable companies and only sells products that she believes in.",
      "She is always looking for the win/win, her main goal being to ensure both parties are satisfied with every transaction and interaction. As Senior Account Manager, she does well to listen to, understand, and care for our clients.",
    ],
    imageSrc: "/images/team/sam.jpg",
    alt: "Sam Applegate Portrait",
  },
  {
    name: "DREW OBER",
    title: "RECOVERY SPECIALIST",
    desc: [
      "Drew has years of experience working with Walmart and is an expert in Walmart deductions. He specifically worked as a deductions analyst at Genpact (Walmart's outsourced deductions management firm), so he understands when and why deductions happen, as well as how to recover them effectively and efficiently.",
      "An excellent communicator, Drew has an impressive ability to understand our clients' needs and answer their questions clearly and concisely. As a Recovery Specialist, he guides our clients through the entire deduction recovery process from start to finish, making sure they are comfortable and confident every step of the way.",
      "His persistence, desire to help others, and passion for networking make him a successful Account Manager and a useful resource for all of our clients.",
    ],
    imageSrc: "/images/team/Drew.jpeg",
    alt: "Drew Ober Portrait",
  },
  {
    name: "CAREY HEROLD",
    title: "MANAGER OF CLIENT RECOVERIES",
    desc: [
      `Carey has spent over 28 years working in the Post Payment Recovery
        Audit Industry - 15 of those years she provided post-audit support
        for Walmart. Carey has worked for the recovery audit industry's
        largest firms: PRGX, Cotiviti, The Audit Group, and APEX Analytix.
        She has personally identified and recovered over $75 million in lost
        profits for retailers, manufacturers, and hospitals. She has
        performed audits on traffic/routing compliance, tax, expense,
        contract pricing, real estate, allowances, advertising, statements,
        and duplicate payments.`,
      ` She did, however, take a break from AP auditing in 2010 when she
        took an opportunity to work on the opposite side of the table as a
        Senior Associate Deduction Specialist with one of the largest
        seafood producers in the world. It was here she used her audit
        expertise to review, reconcile and correct $25MM in Accounts
        Receivable deductions. Carey returned to Accounts Payable auditing
        when she was hired by Apex Analytix in 2011 to join their Walmart
        Audit Development Team. There she learned the processes and
        procedures for each claim type produced through the post-audit
        departments.`,
      `Although she found her role at Apex challenging, she decided her
        passion was to help the supplier community navigate through the
        post-audit experience. Carey has proven her ability to identify
        discrepancies, determine root causes and rectify problems or errors
        in both Accounts Payable and Accounts Receivable capacities. She is
        passionate about preserving and protecting a company's
        profitability.`,
    ],
    imageSrc: "/images/team/carey.png",
    alt: "Carey Herold Portrait",
  },
  {
    name: "JULIA BRIDGES",
    title: "WALMART CONSULTANT",
    desc: [
      "Julia has over 25 years of experience working in process and procedural improvement in several facets of healthcare with a heavy emphasis on quality control. Her persistence in tracking issues benefits vendors and can mitigate future errors.",
      "Julia reviews and furnishes reports that present the opportunities that spotlight deviations and irregularities.",
      "She is always highly focused while helping clients. She communicates clearly and loves solving problems that lead to client success.",
    ],
    imageSrc: "/images/team/julia.jpg",
    alt: "Julia Bridges Portrait",
  },
  {
    name: "KAYLIE STUART",
    title: "AMAZON SPECIALIST",
    desc: [
      "Kaylie has years of experience working with Amazon and is an expert with building and managing listings. She specifically worked as a Product Specialist/Program Manager at Thrasio (known for their buying of Amazon brands and relaunching them).",
      "She has extensive knowledge in all things Amazon and continues to hunt for new learning opportunities. Kaylie is eager to demonstrate her knowledge by bringing our clients success.",
    ],
    imageSrc: "/images/team/Kaylie.jpeg",
    alt: "Kaylie Portrait",
  },
  {
    name: "LANCE PETERSON",
    title: "ACCOUNT SPECIALIST",
    desc: [
      "I have almost 20 years of experience working in Logistics, with 8 years in a managerial role. I have worked closely with E-commerce and know the processes and procedures for shipping and reporting to companies such as Walmart, Amazon, Costco, and Sam's Club.",
      "I enjoy building relationships with clients and customers, and I always go above and beyond for them. I am eager to take on new projects, and I usually won't stop until they are completed. I am a fast learner, hard worker, and extremely loyal.",
      "Currently, I reside in Florida with my wife Jennifer of 15 years and my two daughters. We love spending as much time as we can at Disney and Universal Studios. I have a deep passion for baseball, which can be quite obsessive.",
    ],
    imageSrc: "/images/team/lance.jpg",
    alt: "Lance Portrait",
  },
  {
    name: "ESTEBAN SAGRERO",
    title: "PROGRAMMING CONSULTANT",
    desc: [
      "Esteban is a gifted programmer and consultant. After graduating college with a degree in Computer Science in 2007, he confidently entered the professional technical world. His speed, reliability, and desire to help end-users have helped him excel in the various roles he has filled.",
      "As our Programming Consultant, Esteban deals mostly with the back-end of Retail Consulting Team. He is responsible for automating parts of the deduction recovery process, which has empowered us to provide our clients with faster, more effective, and convenient recoveries.",
      "His contributions have played an invaluable role in helping the company grow, and he plans to keep helping in any way that he can.",
    ],
    imageSrc: "/images/team/Esteban.jpeg",
    alt: "Esteban Sagrero Portrait",
  },
  {
    name: "DALLIN POULSON",
    title: "SOFTWARE DEVELOPER",
    desc: [
      "Dallin is a driven web developer. He creates and maintains all aspects of our website, IT, and other technologies. He studied Information Systems with an emphasis in Application Development at UVU.",
      "He works hard to create a great user experience with fast and beautiful websites and apps. He is passionate about learning new things and always looks for ways to improve our current and new systems.",
    ],
    imageSrc: "/images/team/dallin.jpg",
    alt: "Dallin Poulson Portrait",
  },
  {
    name: "ANIKA MORENO",
    title: "ACCOUNTING MANAGER",
    desc: [
      "Anika was taught accounting principles from a young age. She grew up working for her father, mastering accounting through guided hands-on experience.",
      "Recognizing that accounting came naturally to her and that she found it to be a fulfilling profession, she decided to pursue it as a career. In 2017, she accepted a position as Bookkeeper for Retail Consulting Team and has worked in various accounting roles for various companies since that time.",
      "Throughout her career, she has served as Head of HR, Head of Accounting, Bookkeeper, and currently fills the roles of Small Business Accountant and Office Manager.",
    ],
    imageSrc: "/images/team/Anika.jpeg",
    alt: "Anika Moreno Portrait",
  },


  {
    name: "Marilee Bassett",
    title: "WALMART RECOVERY SPECIALIST",
    desc: [
      `
    Marilee earned her Bachelor's degree from Brigham Young University in Finance.  She has been married for 40 years, has four sons and is  expecting her eleventh grandchild. She has enjoyed a wide variety of employment opportunities and especially loves working with numbers. `,
      `She is one of our Walmart Recovery Specialists working to maximize our customers' payback. She is inspired by the wonderful people she has become acquainted with through Retail Consulting Team. 
    `,
    ],
    imageSrc: "/images/team/marilee.jpeg",
    alt: "Marilee Bassett Portrait",
  },

  {
    name: "DANIELLE SCHWAB",
    title: "AMAZON SALES ASSOCIATE",
    desc: [
      `Danielle is a hard working and driven individual who isn't afraid to face a challenge. She's passionate about her work and knows how to get the job done. Danielle is always proactive about seeking new opportunities to develop and grow in her role.`,
    ],
    imageSrc: "/images/team/Danielle.jpg",
    alt: "McKenna Steele Portrait",
  },
  {
    name: "Tysen Creager",
    title: "MARKETING MANAGER",
    desc: [
      `Tysen is a seasoned marketing professional with a strong dedication to optimizing user experiences and cultivating an online presence. Beyond her office hours, she finds joy in fitness pursuits, reading books, and embarking on adventures with her husband and their dog.`,
    ],
    imageSrc: "/images/team/tysen.jpg",
    alt: "Tysen Creager Portrait",
  },
  {
    name: "Makayla Sonnenburg",
    title: "Deductions",
    desc: [
      `MaKayla is an experienced customer service consultant with over 10 years in various customer service positions.  With great attention to detail and a passion for helping others, she finds her work in service to be very satisfying.`,
      `When MaKayla is not delving enthusiastically into her work, she enjoys spending time with her two young boys.  She hopes to increase her experience and continue improving to provide our clients with the outstanding service they deserve.
     `,
    ],
    imageSrc: "/images/team/makayla.jpg",
    alt: "Makayla Sonnenburg Portrait",
  },
  {
    name: "Isaac Coyle",
    title: "Accountant",
    desc: [
      `Isaac Coyle is a well-rounded and honest person. His competitive spirit and love of numbers has resulted in multiple accolades.  He found his love of accounting and auditing in High school shortly after he was declared state champion in Mathematics.  He has since been ranked among one of the top prospects of the Deloitte Accounting competition of 2021-22.`,
      `Isaac values integrity and doing the right thing above all else. He enjoys video games, and taking his lovely wife on dates at least once a week. He enjoys meeting people, and is always excited to lend a helping hand.  When asked what his proudest achievements are, Isaac is quoted saying "The proudest moments of my life was serving a religious mission for 2 years, and asking my wife of 3 years to marry me."`,
    ],
    imageSrc: "/images/team/isaac.jpg",
    alt: "Isaac Coyle Portrait",
  },
  {
    name: "Carly Pratt",
    title: "Accountant",
    desc: [
      `Carly Pratt has over ten years experience in the accounting field and also has a bachelor's degree in Business Management.  She is a dedicated and hard working individual who loves learning new things and developing new skills.`,
      `She has a beautiful family who she adores and loves more than anything.  She is passionate about helping others.  She enjoys various outdoor activities, but her favorite thing to do is spend time up in the mountains."`,
    ],
    imageSrc: "/images/team/carly.jpeg",
    alt: "Carly Pratt Portrait",
  }
  
];
