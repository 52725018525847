import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer() {
  return (
    <footer className="footer bg-dark pt-3 pb-2">
      <Container>
        <Row className="text-center">
          <Col xs="12" lg="4">
            <a
              className="my-3 footer-links"
              href="mailto:contact@retailconsultingteam.com"
            >
              contact@retailconsultingteam.com
            </a>
          </Col>
          <Col xs="12" lg="4">
            <a className="my-3 footer-links" href="tel:4793650109">
              (479) 365-0109
            </a>
          </Col>
          <Col xs="12" lg="4">
            <a
              className="my-3 footer-links"
              href="https://goo.gl/maps/Szh9kKiSaWmhqcQF7"
            >
              900B South Walton Blvd Suite 1, Bentonville, AR 72712
            </a>
          </Col>
        </Row>
        <Row>
          <p className="text-center text-light mt-4">
            © Copyright 2023, Retail Consulting Team
          </p>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
