import React, { useState } from "react";
import { useEffect } from "react";
import axios from "../../../axios";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Client from "./Client";
import { ListGroup } from "react-bootstrap";
function ClientList({ currentClient, setCurrentClient, clients}) {
 

  console.log("clients", clients);
  return (
    <Row>
      <Col xs="12">
        <ListGroup>
          {clients.map((client) => {
            return (
              <ListGroup.Item
                key={client.client_id}
                className={
                  currentClient?.client_id === client?.client_id
                    ? "active-account"
                    : "account-list-item"
                }
                onClick={() => setCurrentClient(client)}
              >
                {client.client_name}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Col>
    </Row>
  );
}

export default ClientList;
